@import 'styles/_variables';
@import 'styles/_mixins';

.AppSideNav {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    height: 100%;
    padding: 32px 0;

    &_TopLogo {
        margin: auto;
    }

    &_MySLife {
        background-color: $color-brand-light-pink;
        width: 100%;
        padding: 10px 16px;
        margin-top: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: $font-bold;
        font-size: 20px;

        &-logo {
            width: 60px;
            height: 60px;
            border-radius: 30px;
            background-color: rgba(255, 255, 255, 0.5);
            margin-right: 24px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 54px;
                height: 36px;
                object-fit: contain;
                margin: auto;
            }
        }
    }

    &_Menu {
        margin-top: 60px;
        font-weight: $font-bold;
        display: flex;
        flex-direction: column;

        &-item {
            padding: 15px 40px;
            border-left: 5px solid transparent;
            font-size: 20px;
            display: flex;
            align-items: center;

            span {
                margin-left: 15px;
            }

            &--active {
                border-left: 5px solid $color-brand-dark-pink;
                background-color: $color-lightgray-2;
            }
        }

        &-logout {
            span {
                margin-left: 15px;
                margin-top: 4px;
            }
        }
    }
}
