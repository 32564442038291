
//-------------------------------------//
// MEDIA QUERIES
$media-vlg: 1400px;
$media-lg: 1200px;
$media-md: 992px;
$media-sm: 768px;
$media-xs: 480px;

//-------------------------------------//
// COLORS
// Projects color palette
// TODO_REPLACE(projects own color palette)
$color-brand-blue: blue;
$color-brand-light-pink: #F0D7D4;
$color-brand-dark-pink: #E6ABAE;

$color-white: #fff;
$color-lightgray: #e0e0e0;
$color-lightgray-2: #F8F4ED;
$color-darkgray: #333;
$color-darkgray-2: #737373;
$color-lightgray-3: #c8c8c8;
$color-green: #35D367;
$color-green-2: #0C866C;
$color-red: #E63939;
$color-divider: #eee;
$color-body: #334150;
$color-link: $color-body;
$color-error: #d32f2f;
$border-error: #e6abae;
$color-pink: #F2D5D6;
$background-color-body: #ececec;

//-------------------------------------//
// FONTS
// TODO_REPLACE(projects font families)
// $font-family-poppins: 'Poppins', Arial, Sans-Serif;
// $font-family-lato: 'Lato', Arial, Sans-Serif;
$font-family-spartan: 'Spartan', arial, sans-serif;
$font-family-montserrat: 'Montserrat', arial, sans-serif;

$font-family-body: $font-family-spartan;
$font-color-body: $color-body;
$font-size-body: 14px;

$font-light: 300;
$font-normal: 400;
$font-medium: 500;
$font-semi-bold: 600;
$font-bold: 700;
$font-bolder: 900;


//-------------------------------------//
// SPACING
$spacing: 20px;
$spacing-half: $spacing / 2;
$spacing-double: $spacing * 2;
$spacing-third: $spacing * 3;

//-------------------------------------//
// Z-INDEXING
// $zIndex-AppHeaderNav: 1000;
// $zIndex-AppSideNav: 1010;
// ...

//-------------------------------------//
// Global Variables

// Radius
// TODO_REPLACE(projects radiuses)
$border-radius-button: 4px;
$border-radius-small: 3px;
$border-radius-medium: 6px;
$border-radius-large: 9px;

// Box Shadow
// TODO_REPLACE(projects shadows)
$box-shadow-light: 0 1px 3px rgba(#000, 0.12), 0 1px 2px rgba(#000, 0.24);
$box-shadow-medium: 0 3px 6px rgba(#000, 0.16), 0 3px 6px rgba(#000, 0.23);
$box-shadow-heavy: 0 10px 20px rgba(#000, 0.19), 0 6px 6px rgba(#000, 0.23);

//-------------------------------------//
// Component Variables
// TODO_REPLACE(project component styling)
// App Header Nav
// $AppHeaderNav-height: 60px;
// $AppHeaderNav-z-index: $zIndex-AppHeaderNav;

// App Side Nav
// $AppSideNav-width: 240px;
// $AppSideNav-z-index: 160ms;

// ActivityIndicator
// $ActivityIndicator-stroke: $color-brand-blue;

// CheckBox
// $CheckBox-border: 2px solid #E4F0FC;
// $CheckBox-color: #AE77EA;
// $CheckBox-checked-background-color: #AE77EA;

// RadioBox
// $RadioBox-border: 2px solid #E4F0FC;
// $RadioBox-hover-border: 7px solid #e9dcf7;
// $RadioBox-hover-background-color: rgba(#AE77EA, 0.5);
// $RadioBox-checked-border: 7px solid #e9dcf7;
// $RadioBox-checked-background-color: #AE77EA;

// ...
