/*
 *
 * <ActivityIndicator />
 *
 */
// .ActivityIndicator {
//     stroke: $ActivityIndicator-stroke;
// }

/*
 *
 * <Button />
 *
 */
.Button {
    transition-duration: 100ms;
    background-color: $color-brand-dark-pink;
    padding: 15px 5px;
    border-radius: 5px;

    &--default {
        &:hover {
            box-shadow: $box-shadow-light;
        }

        &:active {
            color: $color-brand-blue;
            background-color: #fff;
            box-shadow: inset 0 0 3px rgba(#000, 1);
        }
    }

    &--default.Button--processing {
        color: $color-brand-blue;
        background-color: #fff;
        box-shadow: inset 0 0 3px rgba(#000, 1);
    }

    &--solid-blue {
        border-radius: $border-radius-button;
        color: #fff;
        background-color: $color-brand-blue;
    }
}

/*
 *
 * <CheckBox />
 *
 */
// .CheckBox {
//     &__box {
//         border: $CheckBox-border;
//         color: $CheckBox-color;
//
//         &--checked {
//             background-color: $CheckBox-checked-background-color;
//         }
//     }
// }

/*
 *
 * <RadioBox />
 *
 */
// .RadioBox {
//     &__box {
//         border: $RadioBox-border;
//
//         &:hover {
//             border: $RadioBox-hover-border;
//             background-color: $RadioBox-hover-background-color;
//         }
//
//         &--checked {
//             border: $RadioBox-checked-border !important;
//             background-color: $RadioBox-checked-background-color !important;
//         }
//     }
// }

// ...
