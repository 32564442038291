@import 'styles/_variables';

#root,
.AppContainer {
    height: 100%;
    min-height: 100%;
}

.AppContainer {
    &_Authed {
        background-color: $color-white;
        height: 100vh;
        width: 100vw;
        padding: 30px 20px;
        display: grid;
        grid-template-columns: 350px auto;
        column-gap: 40px;
        overflow: hidden;

        &--compact {
            grid-template-columns: 280px auto;
        }

        @media screen and (max-width: $media-vlg) {
            grid-template-columns: 250px auto;
        }
    }
}
